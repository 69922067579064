import React from "react";
import FirstStep from "./firstStep";
import SecondtStep from "./secondStep";
import ThirdStep from "./thirdStep";

const Steps = (props) => {
  const { step, onNext } = props;

  const Steps = [
    <FirstStep onNext={onNext} />,
    <SecondtStep onNext={onNext} />,
    <ThirdStep />,
  ];
  return (
    <div
    className={`w-full pt-[30px] sm:mb-0 mb-[20px] relative flex sm:block items-start justify-center ${
      step == 2 ? 'min-h-[344px]' : ''
    }`}
  >
      {Steps[step]}
    </div>
  );
};
export default Steps;
