import React, { createContext, useContext, useEffect, useState } from "react";
import { InwiIcon, IAMIcon, OrangeIcon } from "../Components/icons";
import axios from "axios";

const StepsContext = createContext();

export const options = [
  {
    value: "INWI",
    label: "inwi",
    image: <InwiIcon />,
    selected: true,
  },
  {
    value: "IAM",
    label: "IAM",
    image: <IAMIcon />,
    selected: false,
  },
  {
    value: "ORANGE",
    label: "Orange",
    image: <OrangeIcon />,
    selected: false,
  },
];

export const StepsProvider = ({ children }) => {
  const [voucherType, setVoucherType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [VoucherAmount, setVoucherAmount] = useState("");
  const [jawazTag, setJawazTag] = useState("");
  const [jawazBalance, setJawazBalance] = useState(null);
  const [gifts, setGifts] = useState({
    inwi: [],
    iam: [],
    orange: [],
    loading: true,
  });
  const [apiResponse, setApiResponse] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState(
    options.find((option) => option.selected)
  );

 

  useEffect(() => {
  }, []);

  const contextValue = {
    voucherType,
    setVoucherType,
    phoneNumber,
    setPhoneNumber,
    voucherCode,
    setVoucherCode,
    apiResponse,
    setApiResponse,
    selectedOption,
    setSelectedOption,
    currentStep,
    setCurrentStep,
    gifts,
    setGifts,
    setVoucherAmount,
    VoucherAmount,
    setJawazTag,
    jawazTag,
    setJawazBalance,
    jawazBalance
  };

  return (
    <StepsContext.Provider value={contextValue}>
      {children}
    </StepsContext.Provider>
  );
};

export const useSteps = () => {
  return useContext(StepsContext);
};
