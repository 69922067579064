import React from "react";

const Label = (props) => {
  const { label } = props;

  return (
    <div className="w-[35px] h-[35px] flex items-center justify-center rounded-full bg-red-500 aspect-[1/1]">
      <p className="text-white font-bold text-[20px] text-center">{label}</p>
    </div>
  );
};

export default Label;
