import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";

const VoucherURL = "https://shell.wafr.co" //"http://localhost:5050/api/" //
const CompanyId =process.env.REACT_APP_COMPANY_ID;

export const getOffers = async (operator, amount) => {
//  console.log('test')
//   return {
//     "success": true,
//     "products": [
//         {
//             "id": "15",
//             "name": "*1 [SMS]",
//             "operator": "IAM",
//             "supportedAmounts": [
//                 5,
//                 10,
//                 20,
//                 25,
//                 30,
//                 50,
//                 100,
//                 150,
//                 200,
//                 300
//             ]
//         },
//         {
//           "id": "126",
//           "name": "*2 [Sa3a+4G]",
//           "operator": "IAM",
//           "supportedAmounts": [
//               5,
//               10,
//               20,
//               25,
//               30,
//               50,
//               100,
//               150,
//               200,
//               300
//           ]
//       },
//       {
//         "id": "12",
//         "name": "*3 [4G]",
//         "operator": "IAM",
//         "supportedAmounts": [
//             5,
//             10,
//             20,
//             25,
//             30,
//             50,
//             100,
//             150,
//             200,
//             300
//         ]
//     },
//     {
//       "id": "144",
//       "name": "*5 [Nat+inter]",
//       "operator": "IAM",
//       "supportedAmounts": [
//           5,
//           10,
//           20,
//           25,
//           30,
//           50,
//           100,
//           150,
//           200,
//           300
//       ]
//   },
//   {
//     "id": "149",
//     "name": "*6 [Réseaux Sociaux]",
//     "operator": "IAM",
//     "supportedAmounts": [
//         5,
//         10,
//         20,
//         25,
//         30,
//         50,
//         100,
//         150,
//         200,
//         300
//     ]
// }
//     ]
// }
  try {
    const response = await axios.get(
      `${VoucherURL}/api/offers?operator=${operator}&amount=${amount}`
    );
    return response.data;
  } catch (e) {
    return null;
  }
};

export const getAmount = async (voucher) => {

  let url = VoucherURL;
  var payload = {
      "VoucherKey":voucher,
      "CompanyId":CompanyId
  }
  await firebase.auth().signInAnonymously();
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  console.log('Anonymous User Token:', token);
  // return voucher == "fffffffD" ? {amount:30, success:true} : {amount:50, success:true};
  
  try {
    const response = await axios.post(`${url}/api/voucherAmount`,payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        
      },
    });
    if (response.status == 200 && response.data.success == true) {
      console.log(response.data);
      return response.data;
    }
    else{
        return {error : response.data.error, success: false}
    }
  } catch (e) {
    return  {error : "server error", success: false};
  }

};


export const postTopupApiData = async (data) => {

  let url = VoucherURL;
  var payload = {
      "VoucherKey":data.VoucherKey,
      "PhoneNumber" : data.PhoneNumber,
      "Operator":data.Operator,
      "Product":data.Product.toString(),
      "CompanyId":CompanyId
  }
  console.log(payload);
  await firebase.auth().signInAnonymously();
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  //console.log('Anonymous User Token:', token);

  try {
    const response = await axios.post(`${url}/api/voucher/topup`,payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        
      },
    });
    if (response.status == 200 && response.data.success == true) {
      console.log(response.data);
      return response.data;
    }
    else{
        return {error : response.data.error, success: false}
    }
  } catch (e) {
    return  {error : "server error", success: false};
  }

};

export const postJawazBalance = async (data) => {

  let url = VoucherURL;
  var payload = {
      "jawazTag": data,
  }
  console.log(payload);
  await firebase.auth().signInAnonymously();
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  //console.log('Anonymous User Token:', token);
  try {
    const response = await axios.post(`${url}/api/jawazBalance`,payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        
      },
    });
    if (response.status == 200 && response.data.success == true) {
      console.log(response.data);
      return response.data;
    }
    else{
       return {error : response.data.error, success: false}
    }
  } catch (e) {
    return  {error : "server error", success: false};
  }

};

export const postJawazApiData = async (data) => {

  let url = VoucherURL;
  var payload = {
    VoucherKey: data.VoucherKey,
    JawazTags: data.jawazTagNumber,
    CompanyId: CompanyId,
  };
  console.log(payload);
  await firebase.auth().signInAnonymously();
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();
  //console.log('Anonymous User Token:', token);
  //return {success:true}
  try {
    const response = await axios.post(`${url}/api/voucher/jawaz`,payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        
      },
    });
    if (response.status == 200 && response.data.success == true) {
      console.log(response.data);
      return response.data;
    }
    else{
       return {error : response.data.error, success: false}
    }
  } catch (e) {
    return  {error : "server error", success: false};
    return {success:true}
  }

};
