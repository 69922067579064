import React from "react";
import CheckMark from "../../../icons/CheckMark";
import { useTranslation } from "react-i18next";

const ThirdStep = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex flex-col items-center justify-center font-SF_PRO_REGULAR sm:absolute top-0">
      <CheckMark />
      <h1 className="font-bold text-[16px] mt-[14px]">
        {t('congratulationsMessage')}
      </h1>
      <p className="text-center text-[14px] max-w-[180px]">
        {t('congratulationsDescription')}
      </p>
    </div>
  );
};

export default ThirdStep;
