import React from "react";
import { ShellIcon } from "../../icons";
import { useTranslation } from "react-i18next";

const CardHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-[#FFD103] min-h-[200px] w-full shadow-card flex items-center flex-col rounded-t-[8px]">
      <ShellIcon className={"mt-[20px] w-[305px] h-[71px]"} />
      <div className="sm:max-w-[367px] max-w-[85%] text-center mt-[24px] mb-[15px] text-[17px]">
        <p className="font-SF_PRO_REGULAR font-[500] text-[#404040]">
          <span className="font-bold text-[#252525]">{t("title1")}</span>
          {t("title2")}
          <span className="font-semibold">{t("title3")}</span>
        </p>
      </div>
    </div>
  );
};

export default CardHeader;
