import React from "react";

const CheckMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="81"
    height="81"
    viewBox="0 0 81 81"
    fill="none"
  >
    <path
      d="M40.5709 0C18.2995 0 0 18.1895 0 40.5C0 62.8105 18.1576 81 40.5709 81C62.8424 81 81 62.8105 81 40.5C81 18.1895 62.8424 0 40.5709 0ZM35.4641 58.8316L19.4343 42.6316L25.8179 36.2368L35.606 46.0421L59.296 22.3105L65.6795 28.7053L35.4641 58.8316Z"
      fill="#00824A"
    />
  </svg>
);

export default CheckMark;
