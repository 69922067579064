import React from "react";

const Arrow = (props) => {
  const { className, fill = "#FFFFFF", ...etc } = props;

  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...etc}
    >
      <path
        d="M4.78317 6.22977C4.68588 6.22977 4.59521 6.20986 4.51119 6.17006C4.42716 6.13468 4.34534 6.0794 4.26573 6.00422L0.278786 1.97746C0.146109 1.84921 0.0797703 1.69221 0.0797703 1.50646C0.0797703 1.31629 0.143898 1.15487 0.272152 1.02219C0.404829 0.893935 0.566253 0.829807 0.756424 0.829807C0.937749 0.829807 1.09917 0.898357 1.2407 1.03546L4.78317 4.61774L8.32565 1.03546C8.46718 0.898357 8.6286 0.829808 8.80992 0.829808C9.0001 0.829808 9.15931 0.893935 9.28756 1.02219C9.41582 1.15487 9.47994 1.31629 9.47994 1.50646C9.47994 1.69221 9.41582 1.84921 9.28756 1.97746L5.30061 6.00422C5.15025 6.15458 4.97777 6.22977 4.78317 6.22977Z"
        fill="white"
        fill-opacity="0.23"
      />
      <path
        d="M4.78317 6.22977C4.68588 6.22977 4.59521 6.20986 4.51119 6.17006C4.42716 6.13468 4.34534 6.0794 4.26573 6.00422L0.278786 1.97746C0.146109 1.84921 0.0797703 1.69221 0.0797703 1.50646C0.0797703 1.31629 0.143898 1.15487 0.272152 1.02219C0.404829 0.893935 0.566253 0.829807 0.756424 0.829807C0.937749 0.829807 1.09917 0.898357 1.2407 1.03546L4.78317 4.61774L8.32565 1.03546C8.46718 0.898357 8.6286 0.829808 8.80992 0.829808C9.0001 0.829808 9.15931 0.893935 9.28756 1.02219C9.41582 1.15487 9.47994 1.31629 9.47994 1.50646C9.47994 1.69221 9.41582 1.84921 9.28756 1.97746L5.30061 6.00422C5.15025 6.15458 4.97777 6.22977 4.78317 6.22977Z"
        fill={fill}
      />
    </svg>
  );
};

export default Arrow;
