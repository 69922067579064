import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const GiftSkeleton = () => {
  return (
    <div
      className={clsx(
        "h-[41px] w-[80px] bg-gray-300 animate-pulse rounded-[5px] flex items-center justify-around p-2 font-SF_PRO_REGULAR text-[#fffffff5] text-[12px] leading-5 cursor-pointer"
      )}
    >
      <div className="w-4 h-4 bg-gray-400 rounded-full aspect-[1/1] mx-1" />
      <div className="w-[60px] h-[12px] bg-gray-400 rounded" />
    </div>
  );
};

const Gift = (props) => {
  const { label, icon, isActive, onClick, isLoading, description } = props;
  const { i18n } = useTranslation();

  if (isLoading) {
    return <GiftSkeleton />;
  }
  const descriptionPopup = () => {
    return (
      <div className="absolute w-full h-full group">
        <span
          className={clsx(
            "invisible group-hover:visible absolute w-[200px] bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-start py-2 px-4 rounded whitespace-pre-wrap"
          )}
        >
          {description
            .replace(/[,]/g, "\n-")
            .replace("Formules:", "\nFormules:\n-")}
        </span>
      </div>
    );
  };

  return (
    <div
      className={clsx(
        "h-[41px] relative flex items-center justify-center p-2 font-SF_PRO_REGULAR text-[#fffffff5] text-[12px] leading-5 rounded-[5px] cursor-pointer",
        {
          "bg-[#404040] outline outline-4 outline-red-500": isActive,
          "bg-[#999]": !isActive,
        }
      )}
      onClick={onClick}
    >
      {false && descriptionPopup()}
      {icon}
      <p
        className={clsx({
          "ml-[8px]": i18n.dir() === "ltr" && icon,
          "mr-[8px]": i18n.dir() === "rtl" && icon,
        })}
      >
        {label}
      </p>
    </div>
  );
};

export default Gift;
