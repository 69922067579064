import React, { useEffect, useState } from "react";
import Label from "../../../atoms/Label";
import Gift from "../../../atoms/Gift";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { postJawazApiData,postTopupApiData , getOffers, sendVoucher } from "../../../../api/ApiService";
import LoadingSpinner from "../../../atoms/LoadingSpinner";
import { useSteps } from "../../../../utils/Context";

function formatPhoneNumber(input) {
  const phoneNumber = input.replace(/\D/g, "");

  if (phoneNumber.startsWith("0")) {
    return "+212" + input.substring(1);
  } else if (phoneNumber.startsWith("212")) {
    return "+" + phoneNumber;
  }
  return null;
}

const SecondtStep = (props) => {
  const { onNext } = props;
  const {
    selectedOption,
    phoneNumber,
    voucherCode,
    setApiResponse,
    setCurrentStep,
    VoucherAmount,
    setVoucherAmount,
    voucherType,
    jawazTag,
    jawazBalance
  } = useSteps();
  const { t, i18n } = useTranslation();

  const [product, setProduct] = useState('');
  const [offersData, setOffersData]=useState(null);
  const [productList, setProductList] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const skeletonArray = new Array(13).fill(null);
  const handleError = () => setCurrentStep(0);

  const handleNextClick = async () => {
    setIsLoading(true);
    if(voucherType.type && voucherType.type.toString().toLowerCase()  == "topup"){
    const data = {
      VoucherKey: voucherCode,
      PhoneNumber: formatPhoneNumber(phoneNumber),
      Operator: selectedOption.value,
      Product: product,
    };
    if (product && phoneNumber && voucherCode) {
      console.log("TOPUP type")
      try {
        const responseData = await postTopupApiData(data);
        setApiResponse(responseData);
        if (responseData?.success) onNext();
        else handleError();
      } catch (error) {
        console.error("Error sending data:", error);
      }
    }}
    else if(voucherType.type && voucherType.type.toString().toLowerCase() == "jawaz"){
      console.log("JAWAZ type")
      const data = {
        VoucherKey: voucherCode,
        jawazTagNumber: jawazTag,
      };
      if (jawazTag && voucherCode) {
        try {
          const responseData = await postJawazApiData(data);
          setApiResponse(responseData);
          console.log(responseData)
          if (responseData?.success) onNext();
          else handleError();
        } catch (error) {
          console.error("Error sending data:", error);
        }
      }
    }
    setVoucherAmount(null);
    setIsLoading(false);
  };

  useEffect(() => {
    if(voucherType.type && voucherType.type.toString().toLowerCase()  == 'topup'){
    getOffers(selectedOption.value, VoucherAmount.amount)
      .then((res) => {
        console.log(res);
        if (res && res.success && res.products.length > 0)
          setProductList(res.products);
      })
      .catch((error) => {
        console.error('Error fetching offers:', error);
      });}
     
  }, []);

  return (
    <div
      className=" w-full h-full text-[#404040] px-[13px] relative"
      dir={i18n.dir()}
    >
      {voucherType&& voucherType.type && voucherType.type.toString().toLowerCase()  =="topup" &&
     ( <div className="flex w-full mb-[16px]">
        <Label label={"3"} />
        <div className="flex flex-col mx-2">
          <p className="font-SF_PRO_REGULAR font-bold">{t("selectGift")}</p>
          <p className="font-SF_PRO_REGULAR font-normal text-[11px] text-[#9D9D9D] mt-[-2px]">
            {t("giftSelectionDescription")}
          </p>
        </div>
      </div>)}
      {voucherType&& voucherType.type && voucherType.type.toString().toLowerCase()  =="jawaz" &&
     ( <div className="flex w-full mb-[16px]">
        <Label label={"3"} />
        <div className="flex flex-col mt-[5px] mx-2">
          <p className="font-SF_PRO_REGULAR font-bold">{t("recapSolde")}</p>
        </div>
      </div>)}
      {voucherType&& voucherType.type && voucherType.type.toString().toLowerCase()  =="topup" && (<div className="flex flex-wrap gap-x-[8px] gap-y-[13px]">
        {productList
                && productList.map((e) => (
            <Gift
              loading={true}
              label={e.name}
              description={e.description}
              icon={e.icon}
              onClick={() => {
                setProduct(e.id)
              }}
              isActive={e.id == product}
            />
          ))}
        {productList == null && skeletonArray.map(() => <Gift isLoading={true} />)}
      </div>)}
      {voucherType && voucherType.type && voucherType.type.toString().toLowerCase()  == "jawaz" && (
        <div>
        <div className="flex justify-center items-center w-full h-full">
          <div className="border-2 border-[#ffd103] w-[200px] h-[100px] flex items-center justify-center bg-[#ffd10317] rounded-[10px]">
            <p className="text-[20px] font-bold text-black">
              {jawazBalance != null ? `${jawazBalance} DHS` : "balance not available"}
            </p>
          </div>
        </div>
        <div className="flex flex-1  w-full items-center justify-center font-SF_PRO_REGULAR font-normal text-[#9D9D9D] text-[11px] mt-1 relative min-w-max">
          <p>
            {t("voucherSold")} {" "} 
            <span style={{ color: 'green' }}>{VoucherAmount.amount}</span> {t("currency")}
          </p> </div>
          </div>
      )}
      <div className="w-full flex items-center justify-center mt-[41px] mb-[20px]">
        <div
          className={clsx(
            "w-[172px] h-[36px] flex items-center justify-center rounded-[4px] font-SF_PRO_REGULAR font-bold text-[14px] cursor-not-allowed text-white",
            voucherType && voucherType.type && voucherType.type.toString().toLowerCase()  === "topup" ? {
              "bg-[#999] select-none": !product,
              "bg-[#E10717] cursor-pointer": product,
            } : {
              "bg-[#999] select-none": true,
              "bg-[#E10717] cursor-pointer": true,
            }
          )}
          onClick={() => {
            !isLoading && handleNextClick();
          }}
        >
          {!isLoading ? <p>{t("activateButton")}</p> : <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
};

export default SecondtStep;
