import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { StepsProvider } from "./utils/Context";
import firebase from "firebase/app";


var firebaseConfig = {
  apiKey: 'AIzaSyDg7jHV0yMUyjbPmPNWA3nxLDcyBqJkSCg',
  authDomain: 'wafr-shell.firebaseapp.com',
  projectId: 'wafr-shell',
  storageBucket: 'wafr-shell.appspot.com',
  messagingSenderId: '1079056276672',
  appId: '1:1079056276672:web:9e3ddc049b6df51f93a125',
  measurementId: 'G-9KDPB8M7D6',
};

console.log(firebaseConfig)

firebase.initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StepsProvider>
      <App />
    </StepsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
