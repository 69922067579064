import React, { useState } from "react";
import { ArrowIcon } from "../../icons";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { options } from "../../../utils/Context";

const PickerInput = (props) => {
  const { selectedOption, setSelectedOption } = props;
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div
      className={clsx("w-[120px] text-white font-SF_PRO_REGULAR text-[14px]")}
    >
      <div
        onClick={toggleDropdown}
        className={clsx(
          "w-[120px] h-[40px] relative flex items-center px-[8px] justify-start bg-[#535353] cursor-pointer",
          {
            "rounded-[4px]": !isOpen,
            "rounded-t-[4px]": isOpen,
          }
        )}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <div
          className={clsx("flex items-center", {
            "mr-[25px]": i18n.dir() === "ltr",
            "ml-[25px]": i18n.dir() === "rtl",
          })}
        >
          <div
            className={clsx(
              "rounded-full overflow-hidden flex mx-[8px] items-center justify-center"
            )}
          >
            {selectedOption.image}
          </div>
          {selectedOption.label}
        </div>
        <ArrowIcon
          className={clsx("absolute", {
            "left-2": i18n.dir() === "rtl",
            "right-2": i18n.dir() === "ltr",
            "transfrom rotate-180": isOpen,
          })}
        />
      </div>
      {isOpen && (
        <div className="absolute">
          {options.map(
            (option) =>
              option.value !== selectedOption.value && (
                <div
                  key={option.value}
                  onClick={() => handleOptionSelect(option)}
                  className={clsx(
                    "w-[120px] h-[40px] relative flex items-center justify-start px-[10px] bg-[#535353] cursor-pointer"
                  )}
                  aria-haspopup="true"
                >
                  <div className="flex">
                    <div className="rounded-full overflow-hidden flex items-center justify-center mx-[5px]">
                      {option.image}
                    </div>
                    {option.label}
                  </div>
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default PickerInput;
