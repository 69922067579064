import React, { useState } from "react";
import Label from "../../../atoms/Label";
import PickerInput from "../../../atoms/PickerInput";
import { useTranslation } from "react-i18next";
import { getAmount,postJawazBalance } from "../../../../api/ApiService";
import clsx from "clsx";
import { useSteps } from "../../../../utils/Context";

const FirstStep = (props) => {
  const { onNext } = props;
  const { t, i18n } = useTranslation();
  const {
    setSelectedOption,
    selectedOption,
    phoneNumber,
    setPhoneNumber,
    voucherCode,
    setVoucherCode,
    apiResponse,
    setApiResponse,
    VoucherAmount,
    setVoucherAmount,
    jawazTag,
    setJawazTag,
    voucherType,
    setVoucherType,
    jawazBalance,
    setJawazBalance
  } = useSteps();
  const [isValid, setIsValid] = useState(true);

  const validatePhoneNumber = (number) =>
    /^(0|212)\d{9}$/.test(number.replace(/\D/g, ""));

  const validateJawazTag = (number) => 
    /^\d{11}$/.test(number.replace(/\D/g, ""));


  const isVoucherCodeValid = voucherCode.length > 0;
  const isPhoneNumberValid = phoneNumber.length > 0;
  const isJawazTagNumberValid = jawazTag.length > 0;

  const handlePhoneNumberChange =  (e) => {
    const inputValue = e.target.value;

    setPhoneNumber(inputValue);
    setIsValid(inputValue === "" || validatePhoneNumber(inputValue));
  };

  const handleJawazTagNumberChange =  async (e) => {
    setJawazBalance(null)
    const inputValue = e.target.value;

    setJawazTag(inputValue);
    setIsValid(inputValue === "" || validateJawazTag(inputValue));
    if(inputValue === "" || validateJawazTag(inputValue)){
      const responseData = await postJawazBalance(inputValue);
      console.log(responseData)
      setJawazBalance(responseData.balance)
    }
    

  };

  const handleVoucherCodeChange = async (e) => {
    setApiResponse(null);
    setVoucherAmount(null);
    setVoucherType(null);
    const inputValue = e.target.value;
    setVoucherCode(inputValue);
    if(e.target.value.length ==  8){
      const responseData = await getAmount(e.target.value);
      console.log(responseData)
      //voucher amount
      setVoucherAmount(responseData)
      //voucher type
      setVoucherType(responseData)
    }
  };

  const handleNextClick = () => {
    if (isValid && (isPhoneNumberValid || isJawazTagNumberValid) && isVoucherCodeValid) {
      onNext();
    }
  };

  return (
    <div
      className=" w-full h-full text-[#404040] px-[13px] mt-[0px]"
      dir={i18n.dir()}
    >
      <div className="flex sm:w-full w-[90%] mb-[25px]">
        <Label label={"1"} />
        <div className="flex flex-col mt-[5px] mx-2 w-[90%] relative">
          <p className="font-SF_PRO_REGULAR font-bold mb-[15px] sm:text-[16px] text-[14px]">
            {t("enterVoucherCode")}
          </p>
          <div className="w-full">
            <input
              type="text"
              className="shadow-costum sm:w-[320px] w-full bg-[#F4F4F4] h-[40px] placeholder:text-[#838383] indent-4 rounded-[4px] placeholder:text-[13px] focus:outline-none"
              placeholder={t("voucherCodePlaceholder")}
              onChange={handleVoucherCodeChange}
              value={voucherCode}
            />
          </div>
          <div className="flex sm:w-[92%] items-start justify-between">
            <div className="flex-2 w-full">
              {apiResponse && !apiResponse.success && (
                <p className="text-[#E10717] text-[10px] mt-1 w-full">
                  {t(apiResponse.error)}
                </p>
              )}
              {VoucherAmount && !VoucherAmount.success && (
                <p className="text-[#E10717] text-[10px] mt-1 w-full">
                  {t(VoucherAmount.error)}
                </p>
              )}
            </div>
            {VoucherAmount && VoucherAmount.amount && voucherCode.length ==  8 &&
            (<div className="flex flex-1 sm:w-[320px] w-full items-center justify-end font-SF_PRO_REGULAR font-normal text-[#9D9D9D] text-[11px] mt-1 relative min-w-max">
            <p>
              {t("voucherValueLabel")} {" "} 
              <span style={{ color: 'green' }}>{VoucherAmount.amount}</span> {t("currency")}
            </p> </div>) }
          </div>
        </div>
      </div>

      {voucherType && voucherType.type && voucherType.type.toString().toLowerCase() == "topup" &&
      (<div className="flex sm:w-full w-[90%]">
        <Label label={"2"} />
        <div className="flex flex-col justify-between mt-[5px] mx-2 w-full">
          <p className="font-SF_PRO_REGULAR font-bold mb-[15px] sm:text-[16px] text-[14px]">
            {t("operatorAndNumber")}
          </p>
          <div className="flex w-full">
            <PickerInput
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
            <div className="w-full">
              <input
                type="text"
                className="shadow-costum sm:w-[192px] w-[95%] mx-[8px] bg-[#F4F4F4] h-[40px] placeholder:text-[#838383] indent-4 rounded-[4px] placeholder:text-[13px] placeholder:pb-5 focus:outline-none"
                placeholder={t("phoneNumber")}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              {phoneNumber.length > 0 && !isValid && (
                <p className="text-[#E10717] text-[10px] mx-2 min-w-max absolute">
                  {t("invalidPhoneNumberError")}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>)}
      {voucherType && voucherType.type && voucherType.type.toString().toLowerCase() == "jawaz" &&
      (<div className="flex sm:w-full w-[90%]">
        <Label label={"2"} />
        <div className="flex flex-col justify-between mt-[5px] mx-2 w-full">
          <p className="font-SF_PRO_REGULAR font-bold mb-[15px] sm:text-[16px] text-[14px]">
            {t("jawazTagNumber")}
          </p>
          <div className="w-full">
            <input
              type="text"
              className="shadow-costum sm:w-[320px] w-full bg-[#F4F4F4] h-[40px] placeholder:text-[#838383] indent-4 rounded-[4px] placeholder:text-[13px] focus:outline-none"
              placeholder={t("jawazTag")}
              onChange={handleJawazTagNumberChange}
              value={jawazTag}
            />
            {jawazTag.length > 0 && !isValid && (
                <p className="text-[#E10717] text-[10px] mx-2 min-w-max absolute">
                  {t("invalidJawazTagNumberError")}
                </p>
              )}
          </div>
        </div>
      </div>)}

     {voucherType && voucherType.type && voucherType.type.toString().toLowerCase() != null &&
      (<div className="w-full flex items-center justify-center mt-[34px] mb-[20px]">
        <div
          className={clsx(
            "w-[172px] h-[36px] flex items-center justify-center rounded-[4px] cursor-not-allowed font-SF_PRO_REGULAR font-bold text-[14px] text-white sm:mb-[0px] mb-[20px]",
            {
              "bg-[#999] select-none": !(
                isValid &&
                (isPhoneNumberValid ||(isJawazTagNumberValid &&  jawazBalance!=null && jawazBalance!=null)) &&
                isVoucherCodeValid &&
                !apiResponse &&
                VoucherAmount &&
              
                 VoucherAmount.success
              ),
              "bg-[#E10717] cursor-pointer":
                isValid &&
                (isPhoneNumberValid ||  (isJawazTagNumberValid &&  jawazBalance!=null && jawazBalance!=null)) &&
                isVoucherCodeValid &&
                !apiResponse&&
                VoucherAmount && 
              
                VoucherAmount.success,
            }
          )}
          onClick={() => !apiResponse && handleNextClick()}
        >
          <p>{t("verifyButton")}</p>
        </div>
      </div>)}
    </div>
  );
};

export default FirstStep;
