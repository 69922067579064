import ImageBackground from "./assets/images/background.png";
import MobileImageBackground from "./assets/images/mobileBackground.png";
import Steps from "./Components/molecule/Steps";
import CardHeader from "./Components/molecule/CardHeader";
import LanguageSelector from "./Components/atoms/LangSelector";
import { useWindowDimensions } from "./hooks/useWindowDimentions";
import { useSteps } from "./utils/Context";
import React, { useEffect, useRef } from "react";

const BackgroundImage = () => {
  const { width: windowWidth } = useWindowDimensions();

  return (
    <img
      src={windowWidth >= 640 ? ImageBackground : MobileImageBackground}
      alt="background"
      className="w-screen h-full object-cover z-[-1] absolute top-0 right-0"
    />
  );
};

function App() {
  const { currentStep, setCurrentStep } = useSteps();
  const onNext = () => setCurrentStep((prev) => prev + 1);
  const scrollRef = useRef(null);

  useEffect(() => {
    const isMobileView = window.innerWidth < 768;

    if (isMobileView && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div className="w-screen h-screen flex sm:items-center items-end sm:justify-end justify-center">
      <LanguageSelector />
      <BackgroundImage />
      <div className="sm:w-[412px]  w-[95%] shrink-0 bg-white rounded-[8px] sm:mr-[113px] sm:mb-0 mb-[46px]">
        <CardHeader />
        <Steps
          setCurrentStep={setCurrentStep}
          step={currentStep}
          onNext={onNext}
        />
      </div>
      <div ref={scrollRef} />
    </div>
  );
}

export default App;